import { Row, Col, Menu } from "antd";
import footerStyle from "./footerStyle";
export default function Footer() {
  const mobileView = window.innerWidth < 481;
  const footerRow1 = [
    { name: "Contact", redirect: "/contact" },
    { name: "FAQ", redirect: "/faq" },
  ];
  const footerRow2 = [
    { name: "Brands & Businesses", redirect: "https://business.lenny.ai" },
    { name: "Partner", redirect: "/partners" },
    { name: "Sign UP", redirect: "/signup" },
  ];
  const footerRow3 = [
    //{name: "California Policy", redirect: ""},
    { name: "Terms of use", redirect: "/terms" },
    { name: "Privacy Policy", redirect: "/privacy" },
    // {name: "EU Privacy/GDPR", redirect: ""},
  ];

  return (
    <Row style={footerStyle.footer}>
      <div style={{ width: mobileView ? "" : "20%" }}>
        <img
          src={"/applicantImg/LennyFooterLogo.png"}
          alt="Lenny.AI Your First Scholarship Solutions"
          style={footerStyle.footerImage}
        />
      </div>
      <Menu mode={"vertical"} style={footerStyle.footerMenu}>
        {footerRow1.map((items) => (
          <Menu.Item>
            <a href={items.redirect} style={footerStyle.footerText}>
              {items.name}
            </a>
          </Menu.Item>
        ))}
      </Menu>
      <Menu mode={"vertical"} style={footerStyle.footerMenu}>
        {footerRow2.map((items) => (
          <Menu.Item>
            <a href={items.redirect} style={footerStyle.footerText}>
              {items.name}
            </a>
          </Menu.Item>
        ))}
      </Menu>
      <Menu mode={"vertical"} style={footerStyle.footerMenu}>
        {footerRow3.map((items) => (
          <Menu.Item>
            <a href={items.redirect} style={footerStyle.footerText}>
              {items.name}
            </a>
          </Menu.Item>
        ))}
      </Menu>
      <div style={{}}>
        <div style={footerStyle.footerText}>Follow us on</div>
        <a
          href="https://www.instagram.com/lennysocial/?igshid=OGQ5ZDc2ODk2ZA%3D%3D"
          target="_blank"
        >
          <img
            src="/new/insta.webp"
            alt="Instagram"
            style={{
              width: "20px",
              height: "20px",
            }}
          />
        </a>
      </div>
      <div style={{}}>
        <div style={footerStyle.footerText}>
          Copyright Lenny.AI © 2024. All Rights Reserved
        </div>
        <div style={footerStyle.footerText}>
          Lenny is a personalized AI filtering, matching, management, and
          application assistant. Lenny does not guarantee the receipt of
          financial aid nor does upgrading your membership increase your chances
          of gaining any particular award, reward or acceptance.
        </div>
      </div>
    </Row>
  );
}
